import React from 'react'
import { Header } from '../Header/Header'
import "./hero.css"
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"
import NumberCounter from 'number-counter'
import { motion } from 'framer-motion'
const Hero = () => {
    const transistion = { type: 'spring', duration: 3 }
    const mobile = window.innerWidth <= 768 ? true : false;
    return (
        <div className="hero" id='home'>
            <div className="blur hero-blur"></div>
            <div className="left-h">
                <Header />
                <div className="the-best-ad">
                    <motion.div
                        initial={{ left: mobile ? "178px" : "238px" }}
                        whileInView={{ left: '8px' }}
                        transition={{ ...transistion, type: 'tween' }}
                    >

                    </motion.div>
                    <span>the best fitness club in the town</span>
                </div>

                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your</span>
                    </div>
                    <div>
                        <span>Ideal Body</span>
                    </div>
                    <div>
                        <span>
                            In here, we will help you shape and build your ideal body and live up your life to fullest.
                        </span>
                    </div>
                </div>


                <div className="figures">
                    <div>
                        <span>
                            <NumberCounter end={140} start={100} delay='4' prefix="+" />
                        </span>
                        <span>expert coaches</span>
                    </div>
                    <div>
                        <span>
                            <NumberCounter end={978} start={700} delay='4' prefix="+" />
                        </span>
                        <span>members joined</span>
                    </div>
                    <div>
                        <span>
                            <NumberCounter end={50} start={10} delay='4' prefix="+" />
                        </span>
                        <span>fitness programs</span>
                    </div>
                </div>

                <div className="hero-button">
                    <button className="btn">Get Started</button>
                    <button className="btn">Learn More</button>
                </div>
            </div>
            <div className="right-h">

                <button className="btn">Join Now</button>

                <motion.div className="heart-rate"
                    transition={transistion}
                    whileInView={{ right: '4rem' }}
                    initial={{ right: '-1rem' }}
                >
                    <img src={heart} alt="" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>


                <img src={hero_image} className="hero-image" alt="" />
                <motion.img src={hero_image_back} className="hero-image-back" alt=""

                    transition={transistion}
                    initial={{ right: "11rem" }}
                    whileInView={{ right: "20rem" }}
                />


                <motion.div className="calories"
                    initial={{ right: "37rem" }}
                    whileInView={{ right: "27rem" }}
                    transition={transistion}
                >
                    <img src={Calories} alt="" />
                    <div>
                        <span>Calories Burned</span>
                        <span>220 Kcal</span>
                    </div>

                </motion.div>
            </div>
        </div>
    )
}

export default Hero
